export default class Menu {
    $el: HTMLElement;
    $$subMenus: NodeListOf<HTMLUListElement>;

    constructor($el:HTMLElement) {
        this.$el = $el;
        this.$$subMenus = this.$el.querySelectorAll('.js-menuSubMenu');

        this.initListeners();
        this.updateViewportHeight();
        this.updateSubMenuHeights();
    }

    initListeners(): void {
        if (typeof document.fonts.onloadingdone === 'function') {
            document.fonts.onloadingdone = this.updateSubMenuHeights.bind(this);
        }

        window.addEventListener('load', () => {
            this.updateSubMenuHeights();
        }, false);

        window.addEventListener('resize', () => {
            this.updateViewportHeight();
        }, false);
    }

    updateSubMenuHeights(): void {
        this.$$subMenus.forEach(($subMenu) => {
            const $box = $subMenu.closest('.js-menuSubMenuBox');
            const height = $subMenu.offsetHeight;

            if ($box instanceof HTMLElement) {
                $box.style.setProperty('--height', `${height}px`);
            }
        });
    }

    updateViewportHeight(): void {
        this.$el.style.setProperty('--viewport-height', `${window.innerHeight}px`);
    }
}