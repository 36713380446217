import type { CartUpdateSubotalEvent } from './Cart';

export default class Nav {
    $cartNumProducts: HTMLElement;
    $el: HTMLElement;
    $menu: HTMLElement;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$cartNumProducts = this.$el.querySelector('.js-navCartNumProducts')!;

        this.initListeners();
    }

    initListeners(): void {
        document.addEventListener('cart:updatesubtotal', this.onCartUpdateSubtotal.bind(this), false);
        window.addEventListener('scroll', this.onScroll.bind(this), false);
    }

    onCartUpdateSubtotal(e: CartUpdateSubotalEvent): void {
        this.$cartNumProducts.textContent = String(e.detail.numProducts === 0 ? '' : e.detail.numProducts);
    }

    onScroll(): void {
        this.$el.classList.toggle('has-scrolled', window.scrollY > 0);
    }
}
