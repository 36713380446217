import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Equinox {
    $el: HTMLElement;
    $scroller: HTMLElement;

    constructor($el) {
        this.$el = $el;
        this.$scroller = $el.closest('.js-equinoxScroller') ?? window;

        this.init();
    }

    init(): void {
        const once = this.$el.dataset.equinoxOnce === 'true';
        let horizontal = false;

        if (this.$scroller instanceof HTMLElement) {
            horizontal = this.$scroller.scrollWidth > this.$scroller.offsetWidth;
        }

        ScrollTrigger.create({
            horizontal,
            invalidateOnRefresh: true,
            scroller: this.$scroller,
            trigger: this.$el,
            onToggle: (self) => {
                const $el = self.trigger as HTMLElement;
                $el.classList.toggle('is-visible', self.isActive);
            },
            onUpdate: (self) => {
                const $el = self.trigger as HTMLElement;
                const equinox = self.progress * -2 + 1;

                $el.style.setProperty('--equinox', String(equinox));
                $el.style.setProperty('--equinox-abs', String(Math.abs(equinox)));
                $el.style.setProperty('--equinox-pos', String(self.progress));
            },
        });
    }
}
