import { parseString } from '../lib/Utils';
import json from '../data/calculator.json';
import lang from '../data/lang.json';

interface CalculatorProduct {
    $el: HTMLFormElement;
    $heading: HTMLElement;
    $inputQuantity: HTMLInputElement;
    $inputSellingPlanId: HTMLInputElement;
    $interval: HTMLElement;
}

export default class Calculator {
    $$productTexts: NodeListOf<HTMLElement>;
    $el: HTMLElement;
    $inputQuantity: HTMLInputElement;
    $numCupsHeading: HTMLElement;
    $quantity: HTMLElement;
    $quantityTooltip: HTMLElement;
    lang: { [key: string]: string };
    products: { [key: string]: CalculatorProduct };
    productTypes: string[];

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$$productTexts = this.$el.querySelectorAll('.js-calculatorProductText')!;
        this.$inputQuantity = this.$el.querySelector('input[name=quantity]')!;
        this.$numCupsHeading = this.$el.querySelector('.js-calculatorNumCupsHeading')!;
        this.$quantity = this.$el.querySelector('.js-calculatorQuantity')!;
        this.$quantityTooltip = this.$el.querySelector('.js-calculatorQuantityTooltip')!;

        this.lang = lang[document.documentElement.lang]?.calculator ?? {};
        this.products = {};
        this.productTypes = ['beans', 'cups'];
        this.productTypes.forEach((productType) => {
            const $product = this.$el.querySelector(`.js-calculatorProduct[data-product=${productType}]`);

            if ($product instanceof HTMLFormElement) {
                this.products[productType] = {
                    $el: $product,
                    $heading: $product.querySelector('.js-calculatorProductHeading')!,
                    $inputQuantity: $product.querySelector('input[name=quantity]')!,
                    $inputSellingPlanId: $product.querySelector('input[name=selling_plan_id]')!,
                    $interval: $product.querySelector('.js-calculatorProductInterval')!,
                };
            }
        });

        this.updateResults();
        this.updateTooltip();
        this.initListeners();
    }

    initListeners() {
        this.$inputQuantity.addEventListener('pointerdown', this.onQuantityInputPointerDown.bind(this), false);
        this.$inputQuantity.addEventListener('pointerup', this.onQuantityInputPointerUp.bind(this), false);
        this.$inputQuantity.addEventListener('change', this.onQuantityInputChange.bind(this), false);
        this.$inputQuantity.addEventListener('input', this.onQuantityInputInput.bind(this), false);

        this.$$productTexts.forEach(($productText) => {
            $productText.addEventListener(
                'animationend',
                (e) => {
                    if (e.currentTarget instanceof HTMLElement) {
                        e.currentTarget.classList.remove('is-animating');
                    }
                },
                false,
            );
        });
    }

    onQuantityInputChange(): void {
        this.updateResults();
    }

    onQuantityInputInput(): void {
        this.updateTooltip();
    }

    onQuantityInputPointerDown(): void {
        this.$quantity.classList.add('is-dragging');
    }

    onQuantityInputPointerUp(): void {
        this.$quantity.classList.remove('is-dragging');
    }

    updateResults(): void {
        const quantity = +this.$inputQuantity.value;
        this.$numCupsHeading.textContent = quantity + ' ' + this.lang[quantity === 1 ? 'num_cups_one' : 'num_cups_many'];

        if (typeof json === 'object') {
            const data = json[quantity] ?? null;

            if (data) {
                this.productTypes.forEach((productType) => {
                    if (!data.hasOwnProperty(productType) || !this.products.hasOwnProperty(productType)) {
                        return;
                    }

                    const product = this.products[productType];
                    const productData = data[productType];

                    product.$el.action = productData[`url_${document.documentElement.lang}`];

                    if (productType === 'cups') {
                        // Cups
                        product.$heading.textContent = parseString(this.lang['product_cups'] ?? '', {
                            quantity: productData.quantity * 60,
                        });
                    } else {
                        // Beans
                        product.$el.dataset.calculatorProduct = productData.product;
                        product.$heading.textContent = parseString(this.lang['product_beans'] ?? '', {
                            product: productData.product,
                            quantity: productData.quantity * (productData.product === '250g' ? 3 : 1),
                        });
                    }

                    product.$inputQuantity.value = String(productData.quantity);
                    product.$inputSellingPlanId.value = productData.selling_plan_id;
                    product.$interval.textContent = parseString(productData.interval === 1 ? this.lang.weeks_one : this.lang.weeks_many, {
                        weeks: productData.interval,
                    });
                });

                this.$$productTexts.forEach(($productText) => {
                    $productText.classList.add('is-animating');
                });
            }
        }
    }

    updateTooltip() {
        const val = +this.$inputQuantity.value;
        const max = +this.$inputQuantity.max;
        const min = +this.$inputQuantity.min;
        const multiplier = (val - min) / (max - min);

        this.$el.style.setProperty('--quantity', String(val));
        this.$quantityTooltip.style.setProperty('--left', `${multiplier * 100}%`);
        this.$quantityTooltip.style.setProperty('--multiplier', String(multiplier));
        this.$quantityTooltip.textContent = String(val);
    }
}
