import CartLineItem from './CartLineItem';
import Shopify, { ShopifyLineItemAttribute } from '../lib/Shopify';
import Utils from '../lib/Utils';
import type { ShopifyCart, ShopifyCartUpdateEvent } from '../lib/Shopify';
import type { CartLineItemRemoveEvent, CartLineItemUpdateQuantityEvent } from './CartLineItem';
import type { ProductAddToCartEvent } from './Product';

export interface CartUpdateSubotalEvent extends CustomEvent {
    detail: {
        numProducts: number;
        subtotal: number;
    };
}

export default class Cart {
    $addNote: HTMLButtonElement;
    $checkout: HTMLButtonElement;
    $el: HTMLElement;
    $hide: HTMLButtonElement;
    $lineItems: HTMLUListElement;
    $note: HTMLTextAreaElement;
    $noteChars: HTMLElement;
    $subtotalValue: HTMLElement;
    $templateLineItem: HTMLTemplateElement;
    $toggleInput: HTMLInputElement;
    checkoutUrl: string | null;
    shopify: Shopify;
    subtotal: number;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$addNote = this.$el.querySelector('.js-cartAddNote')!;
        this.$checkout = this.$el.querySelector('.js-cartCheckout')!;
        this.$hide = this.$el.querySelector('.js-cartHide')!;
        this.$lineItems = this.$el.querySelector('.js-cartLineItems')!;
        this.$note = this.$el.querySelector('.js-cartNote')!;
        this.$noteChars = this.$el.querySelector('.js-cartNoteChars')!;
        this.$subtotalValue = this.$el.querySelector('.js-cartSubtotalValue')!;
        this.$templateLineItem = this.$el.querySelector('.js-cartTemplateLineItem')!;
        this.$toggleInput = document.querySelector('#cartToggleInput')!;
        this.checkoutUrl = null;
        this.shopify = new Shopify();
        this.subtotal = 0;

        this.init();
        this.initListeners();
    }

    hide(): void {
        this.$toggleInput!.checked = false;
    }

    async init(): Promise<void> {
        this.isLoading = true;
        const cart = await this.shopify.getOrCreateCart();

        if (cart) {
            this.renderCart(cart);
            this.isLoading = false;
        }
    }

    initListeners(): void {
        document.addEventListener('product:addtocart', this.onProductAddToCart.bind(this), false);
        document.addEventListener('shopify:cartupdate', this.onShopifyCartUpdate.bind(this), false);

        this.$el.addEventListener('click', this.onClick.bind(this), false);
        this.$el.addEventListener('cartlineitem:remove', this.onLineItemRemove.bind(this), false);
        this.$el.addEventListener('cartlineitem:updatequantity', this.onLineItemUpdateQuantity.bind(this), false);

        this.$addNote.addEventListener('click', this.onAddNoteClick.bind(this), false);
        this.$hide.addEventListener('click', this.onHideClick.bind(this), false);
        this.$checkout.addEventListener('click', this.onCheckoutClick.bind(this), false);
        this.$note.addEventListener('input', this.onNoteInput.bind(this));
    }

    onCheckoutClick(e: PointerEvent): void {
        if (this.checkoutUrl) {
            window.location.href = this.checkoutUrl;
        }
    }

    onAddNoteClick(e: PointerEvent): void {
        this.$addNote.classList.add('is-loading');

        this.shopify.addNoteToCart(this.$note.value).then(() => {
            this.$addNote.classList.remove('is-loading');
            this.$addNote.textContent = this.$addNote.dataset.labelSaved ?? '';
        });
    }

    onCheckoutWithoutNodeClick(e: PointerEvent): void {
        if (this.checkoutUrl) {
            window.location.href = this.checkoutUrl;
        }
    }

    onClick(e: Event): void {
        if (e.currentTarget === e.target) {
            this.$toggleInput!.checked = false;
        }
    }

    onHideClick(): void {
        this.hide();
    }

    onLineItemRemove(e: CartLineItemRemoveEvent): void {
        if (e.detail.id) {
            this.isLoading = true;
            this.shopify.removeLineItem(e.detail.id);
            this.isLoading = false;
        }
    }

    onLineItemUpdateQuantity(e: CartLineItemUpdateQuantityEvent): void {
        this.updateSubtotal();
        this.isLoading = true;
        this.shopify.updateLineItemQuantity(e.detail.id, e.detail.quantity, e.detail.attributes);
    }

    onNoteInput(e: InputEvent): void {
        if (e.currentTarget instanceof HTMLTextAreaElement) {
            const maxChars = +(this.$note.dataset.maxChars ?? 0);

            if (this.$note.value.length > maxChars) {
                this.$note.value = this.$note.value.substring(0, maxChars);
                e.preventDefault();
            }
        }

        this.$noteChars.textContent = String(this.$note.value.length);
    }

    onProductAddToCart(e: ProductAddToCartEvent): void {
        this.show();
        this.isLoading = true;
        this.shopify.addToCart(e.detail.merchandiseId, e.detail.quantity, e.detail.attributes ?? [], e.detail.sellingPlanId);
    }

    onShopifyCartUpdate(e: ShopifyCartUpdateEvent) {
        this.renderCart(e.detail.cart);
        this.isLoading = false;
    }

    renderCart(cart: ShopifyCart): void {
        if (this.$lineItems instanceof HTMLUListElement) {
            this.$lineItems.innerHTML = '';
        }

        if (cart.checkoutUrl) {
            this.checkoutUrl = cart.checkoutUrl;
        }

        if (cart.note?.length) {
            this.$note.value = cart.note;
        }

        if (cart.lines?.edges?.length) {
            cart.lines.edges.forEach((edge) => {
                const node = edge.node;

                if (this.$templateLineItem) {
                    const $lineItem: Node | undefined = this.$templateLineItem.content.firstElementChild?.cloneNode(true);

                    if ($lineItem instanceof HTMLLIElement) {
                        const lineItem = new CartLineItem($lineItem);
                        lineItem.init(node);

                        if (this.$lineItems instanceof HTMLUListElement) {
                            this.$lineItems.appendChild($lineItem);
                        }
                    }
                }
            });
        }

        this.updateSubtotal();
    }

    show(): void {
        this.$toggleInput!.checked = true;
    }

    updateSubtotal(): void {
        const $$children = Array.from(this.$lineItems.children);
        let numProducts = $$children.length;
        let subtotal = 0;

        $$children.forEach(($lineItem) => {
            if ($lineItem instanceof HTMLLIElement) {
                subtotal += +($lineItem.dataset.price ?? 0) * +($lineItem.dataset.quantity ?? 1);
            }
        });

        this.$el.classList.toggle('is-empty', $$children.length === 0);
        this.$subtotalValue.textContent = Utils.formatCurrency(subtotal);

        this.$el.dispatchEvent(
            new CustomEvent('cart:updatesubtotal', {
                bubbles: true,
                detail: {
                    numProducts,
                    subtotal,
                },
            }),
        );
    }

    /**
     * Getters & setters
     */

    get isLoading(): boolean {
        return this.$el.classList.contains('is-loading');
    }

    set isLoading(isLoading: boolean) {
        this.$el.classList.toggle('is-loading', isLoading);
    }
}
