import lang from '../data/lang.json';
import Shopify from '../lib/Shopify';
import Utils from '../lib/Utils';
import type { QuantityEvent } from './Quantity';

interface Product {
    color: string;
    index: number;
    title: string;
    variant: string;
}

interface SelectedProduct {
    quantity: number;
    title: string;
    variant: string;
}

export default class Subscribe {
    $$products: NodeListOf<HTMLElement>;
    $$selectedProducts: NodeListOf<HTMLElement>;
    $billingInterval: HTMLElement;
    $billingPrice: HTMLElement;
    $cupsPerDay: HTMLInputElement;
    $el: HTMLElement;
    $form: HTMLFormElement;
    $inputId: HTMLInputElement;
    $inputProducts: HTMLInputElement;
    $inputQuantity: HTMLInputElement;
    $inputSellingPlanId: HTMLSelectElement;
    $inputToken: HTMLInputElement;
    $submit: HTMLButtonElement;
    lang: { [key: string]: string };
    productCupsPerShipment: number;
    productHandle: string;
    productPrice: number;
    maxProducts: number;
    params: URLSearchParams;
    selectedProducts: Product[];
    shopify: Shopify;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$$products = this.$el.querySelectorAll('.js-subscribeProduct');
        this.$$selectedProducts = this.$el.querySelectorAll('.js-subscribeSelectedProduct');
        this.$billingInterval = this.$el.querySelector('.js-subscribeBillingInterval')!;
        this.$billingPrice = this.$el.querySelector('.js-subscribeBillingPrice')!;
        this.$cupsPerDay = this.$el.querySelector('.js-subscribeCupsPerDay')!;
        this.$form = this.$el.querySelector('.js-subscribeForm')!;
        this.$inputId = this.$el.querySelector('input[name=id]')!;
        this.$inputProducts = this.$el.querySelector('input[name=products]')!;
        this.$inputQuantity = this.$el.querySelector('input[name=quantity]')!;
        this.$inputSellingPlanId = this.$el.querySelector('select[name=selling_plan_id]')!;
        this.$inputToken = this.$el.querySelector('input[name=token]')!;
        this.$submit = this.$el.querySelector('.js-subscribeSubmit')!;

        this.lang = lang[document.documentElement.lang]?.subscribe ?? {};
        this.maxProducts = this.$$selectedProducts.length;
        this.params = new URLSearchParams(window.location.search);
        this.productCupsPerShipment = +(this.$el.dataset.subscribeProductCupsPerShipment ?? 0);
        this.productHandle = this.$el.dataset.subscribeProductHandle ?? '';
        this.productPrice = +(this.$el.dataset.subscribeProductPrice ?? 0);
        this.selectedProducts = [];
        this.shopify = new Shopify();

        if (!this.productCupsPerShipment) {
            console.error('Component configuration error: Cups per shipment missing.');
            return;
        }

        if (this.productHandle === '') {
            console.error('Component configuration error: Product handle missing.');
            return;
        }

        if (!this.productPrice) {
            console.error('Component configuration error: Product price missing.');
            return;
        }

        if (this.params.get('quantity')) {
            const quantity = this.params.get('quantity') ?? '1';
            this.$inputQuantity.value = quantity;
        }

        this.initListeners();
        this.initSellingPlans();
        this.updatePrice();
    }

    async initSellingPlans(): Promise<void> {
        const productHandle: string | null = this.$el.dataset.subscribeProductHandle ?? null;
        let sellingPlanId: string | null = this.params.get('selling_plan_id');

        if (sellingPlanId) {
            sellingPlanId = window.atob(sellingPlanId);
        }

        if (productHandle) {
            this.$inputSellingPlanId.innerHTML = '';
            const sellingPlans = await this.shopify.getProductSellingPlans(productHandle);

            // Populate dropdown menu
            sellingPlans.forEach((sellingPlan) => {
                const $option = document.createElement('option');
                $option.dataset.discount = String(sellingPlan.discount);
                $option.dataset.discountType = String(sellingPlan.discountType);
                $option.dataset.intervalDays = String(sellingPlan.intervalDays);
                $option.dataset.intervalWeeks = String(sellingPlan.intervalWeeks);
                $option.value = sellingPlan.id;

                if (sellingPlanId && sellingPlanId === sellingPlan.id) {
                    $option.selected = true;
                }

                if (sellingPlan.intervalWeeks === 1) {
                    $option.textContent = `${this.lang.every} ${this.lang.weeks_one}`;
                } else {
                    $option.textContent = `${this.lang.every} ${sellingPlan.intervalWeeks} ${this.lang.weeks_many}`;
                }

                this.$inputSellingPlanId.appendChild($option);
            });

            // Trigger change event to update UI
            this.$inputSellingPlanId.dispatchEvent(new Event('change'));

            this.updateCupsPerDay();
            this.updatePrice();
        }
    }

    initListeners(): void {
        this.$form.addEventListener('submit', this.onSubmit.bind(this));
        this.$inputQuantity.addEventListener('change', this.onUpdateSellingPlanIdOrQuantity.bind(this), false);
        this.$inputSellingPlanId.addEventListener('change', this.onUpdateSellingPlanIdOrQuantity.bind(this), false);

        this.$$products.forEach(($product) => {
            $product.addEventListener('quantity:decrement', this.onProductRemove.bind(this), false);
            $product.addEventListener('quantity:increment', this.onProductAdd.bind(this), false);
        });
    }

    onProductAdd(e: QuantityEvent): void {
        const $product = e.currentTarget;

        if ($product instanceof HTMLElement) {
            const color: string = $product.dataset.subscribeFlavorColor ?? '';
            const index: number = +($product.dataset.subscribeFlavorIndex ?? 0);
            const title: string = $product.dataset.subscribeFlavorTitle ?? '';
            const variant: string = $product.dataset.subscribeFlavorVariant ?? '';

            this.selectedProducts.push({
                color,
                index,
                title,
                variant,
            });

            this.update();
        }
    }

    onProductRemove(e: QuantityEvent): void {
        const $product = e.currentTarget;

        if ($product instanceof HTMLElement) {
            const index: number = +($product.dataset?.subscribeFlavorIndex ?? 0);
            const removeIndex = this.selectedProducts.findIndex((product) => product.index === index);
            this.selectedProducts.splice(removeIndex, 1);
            this.update();
        }
    }

    onSubmit(e: Event) {
        e.preventDefault();

        let merchandiseId: string = this.$inputId.value;
        let quantity: number = this.quantity;
        let sellingPlanId: string | null = this.$inputSellingPlanId.value;

        if (sellingPlanId.trim() === '') {
            sellingPlanId = null;
        }

        document.dispatchEvent(
            new CustomEvent('product:addtocart', {
                detail: {
                    attributes: [
                        {
                            key: '_appstle-bb-id',
                            value: this.$inputToken.value,
                        },
                        {
                            key: 'products',
                            value: this.$inputProducts.value,
                        },
                    ],
                    quantity,
                    sellingPlanId,
                    merchandiseId,
                },
            }),
        );
    }

    update(): void {
        this.isComplete = this.selectedProducts.length === this.$$selectedProducts.length;
        this.selectedProducts.sort((a: Product, b: Product) => a.index - b.index);

        // Update submit button label
        if (this.selectedProducts.length < this.$$selectedProducts.length) {
            const remaining = this.$$selectedProducts.length - this.selectedProducts.length;
            this.$submit.textContent = Utils.parseString(this.lang[remaining === 1 ? 'add_one' : 'add_many'], { remaining });
        } else {
            this.$submit.textContent = this.lang.cta;
        }

        this.$$selectedProducts.forEach(($selectedProduct, i) => {
            const $fill = $selectedProduct.querySelector('.js-subscribeSelectedProductFill');
            const $title = $selectedProduct.querySelector('.js-subscribeSelectedProductTitle');
            const selectedProduct: Product | null = this.selectedProducts[i] ?? null;

            if ($fill instanceof HTMLElement) {
                $fill.style.backgroundColor = selectedProduct ? selectedProduct.color : 'currentColor';
            }

            if ($title instanceof HTMLElement) {
                $title.textContent = selectedProduct ? selectedProduct.title : ' ';
            }

            $selectedProduct.classList.toggle('is-selected', selectedProduct !== null);
        });

        const products: SelectedProduct[] = [];

        this.selectedProducts.forEach((selectedProduct) => {
            const product = products.find((product) => product.title === selectedProduct.title);

            if (product) {
                product.quantity = product.quantity + 1;
            } else {
                products.push({
                    quantity: 1,
                    title: selectedProduct.title,
                    variant: selectedProduct.variant,
                });
            }
        });

        const productsFormatted: string[] = products.map((product) => {
            return `${product.quantity}x ${product.title}` + (product.variant !== 'Default Title' ? ` - ${product.variant}` : '');
        });

        this.$inputProducts.value = productsFormatted.join(', ');
    }

    updateCupsPerDay(): void {
        const $selectedInterval = this.$inputSellingPlanId.options[this.$inputSellingPlanId.selectedIndex];

        if (this.productCupsPerShipment && this.productPrice) {
            if ($selectedInterval && $selectedInterval.dataset.intervalDays) {
                const interval: number = +$selectedInterval.dataset.intervalDays;
                const cupsPerDay = Math.round((this.productCupsPerShipment * this.quantity) / interval);
                this.$cupsPerDay.textContent = String(cupsPerDay);
            }
        }
    }

    updatePrice(): void {
        const $selectedInterval = this.$inputSellingPlanId.options[this.$inputSellingPlanId.selectedIndex];

        if ($selectedInterval && this.productPrice) {
            const interval = $selectedInterval.textContent;

            this.$billingPrice.textContent = Utils.formatCurrency(this.productPrice * this.quantity);

            if (interval) {
                this.$billingInterval.textContent = interval.toLowerCase();
            }
        }
    }

    onUpdateSellingPlanIdOrQuantity(): void {
        this.updateCupsPerDay();
        this.updatePrice();
    }

    /**
     * Getters & setters
     */

    get isComplete(): boolean {
        return this.$el.classList.contains('is-complete');
    }

    set isComplete(isComplete: boolean) {
        this.$el.classList.toggle('is-complete', isComplete);
    }

    get quantity(): number {
        return +this.$inputQuantity.value;
    }

    set quantity(quantity: number) {
        this.$inputQuantity.value = String(quantity);
    }
}
