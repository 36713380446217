window.app = {
    bindings: [],

    bind($root, name, obj, options) {
        $root.querySelectorAll(`.js-${name}`).forEach(($el) => {
            $el.__APP__ ||= {};

            if ($el.__APP__[name] === undefined) {
                $el.__APP__[name] = new obj($el, options);
            }
        });
    },

    bindAll($root) {
        this.bindings.forEach((binding) => {
            this.bind($root, binding.name, binding.obj, binding.options || {});
        });
    },

    init(bindings) {
        this.bindings = bindings;

        const mutationObserver = new MutationObserver((mutations) => {
            let targets = [];

            mutations.forEach((mutationRecord) => {
                if (!targets.includes(mutationRecord.target)) {
                    this.bindAll(mutationRecord.target);
                    targets.push(mutationRecord.target);
                }
            });

            document.body.dispatchEvent(
                new CustomEvent('domchanged', {
                    detail: {
                        targets,
                    },
                }),
            );
        });

        mutationObserver.observe(document.documentElement, {
            childList: true,
            subtree: true,
        });

        this.bindAll(document);
    },
};
