export const parseString = (template: string, values: object): string => {
    const regExp: RegExp = /{+\s?([^{}\s]*)\s?}+/g;

    return template.replace(regExp, (substring, key) => {
        return values[key] ?? '';
    });
};

export const setElementProperty = ($root: HTMLElement, selector: string, property: string, value: any): void => {
    $root.querySelectorAll<HTMLElement>(selector).forEach(($el) => {
        if ($el.hasOwnProperty(property)) {
            $el[property] = value;
        }
    });
};

export default class Utils {
    static formatCurrency(number: number): string {
        const format = Intl.NumberFormat(document.documentElement.lang, {
            currency: 'EUR',
            style: 'currency',
        });

        return format.format(number);
    }

    static parseString(template: string, values: object): string {
        const regExp: RegExp = /{+\s?([^{}\s]*)\s?}+/g;

        return template.replace(regExp, (substring, key) => {
            return values[key] ?? '';
        });
    };

    static setElementProperty($root: HTMLElement, selector: string, property: string, value: any): void {
        $root.querySelectorAll<HTMLElement>(selector).forEach(($el) => {
            if ($el[property] !== undefined) {
                $el[property] = value;
            }
        });
    }
}
