export interface ShopifyCart {
    checkoutUrl: string;
    id: string;
    lines: {
        edges: [
            {
                node: ShopifyCartLineItem;
            },
        ];
    };
    note: string;
}

export interface ShopifyCartLineItem {
    attributes: [
        {
            key: string;
            value: string;
        },
    ];
    id: string;
    merchandise: {
        id: string;
        price: ShopifyPrice;
        product: {
            featuredImage: {
                transformedSrc: string;
            };
            handle: string;
            id: string;
            requiresSellingPlan: boolean;
            title: string;
        };
        title: string;
    };
    quantity: number;
    sellingPlanAllocation: {
        sellingPlan: {
            id: string;
            name: string;
            priceAdjustments: ShopifyPriceAdjustment[];
        };
    };
}

export interface ShopifyCartUpdateEvent extends CustomEvent {
    detail: {
        cart: ShopifyCart;
    };
}

export interface ShopifyError {
    message: string;
}

export interface ShopifyLineItemAttribute {
    key: string;
    value: string;
}

export interface ShopifyPrice {
    amount: string;
    currencyCode: string;
}

export interface ShopifyPriceAdjustment {
    adjustmentValue: {
        __typename: string;
        adjustmentAmount?: ShopifyPrice;
        adjustmentPercentage?: number;
    };
}

export interface SellingPlan {
    discount: number | null;
    discountType: 'fixed' | 'percentage' | null;
    id: string;
    intervalDays: number;
    intervalWeeks: number;
    name: string;
}

export default class Shopify {
    cart: ShopifyCart | null;
    cartFields: string;
    endpoint: string;

    constructor() {
        this.cart = null;
        this.endpoint = '/shopify.php';

        this.cartFields = `
            checkoutUrl
            id
            lines(first: 100) {
                edges {
                    node {
                        attributes {
                            key
                            value
                        }
                        id
                        merchandise {
                            ... on ProductVariant {
                                id
                                title
                                price {
                                    amount
                                    currencyCode
                                }
                                product {
                                    id
                                    featuredImage {
                                        transformedSrc(maxHeight: 256, maxWidth: 256)
                                    }
                                    handle
                                    title
                                    requiresSellingPlan
                                }
                            }
                        }
                        quantity
                        sellingPlanAllocation {
                            sellingPlan {
                                id
                                name
                                options {
                                    name
                                    value
                                }
                                priceAdjustments {
                                    adjustmentValue {
                                        ... on SellingPlanFixedAmountPriceAdjustment {
                                            __typename
                                            adjustmentAmount {
                                                amount
                                                currencyCode
                                            }
                                        }
                                        ... on SellingPlanFixedPriceAdjustment {
                                            __typename
                                            price {
                                                amount
                                                currencyCode
                                            }
                                        }
                                        ... on SellingPlanPercentagePriceAdjustment {
                                            __typename
                                            adjustmentPercentage
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            note`;
    }

    /**
     * https://shopify.dev/docs/api/storefront/unstable/mutations/cartNoteUpdate
     */
    async addNoteToCart(note: string): Promise<boolean> {
        if (!this.cart) {
            console.error('Shopify Cart object not initialized.');
            return false;
        }

        const query = `
            mutation cartNoteUpdate($cartId: ID!, $note: String) {
                cartNoteUpdate(cartId: $cartId, note: $note) {
                    cart {
                        ${this.cartFields}
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`;

        const res = await this.query(query, {
            cartId: this.cart.id,
            note: note,
        });

        if (res.cartNoteUpdate?.cart && res.cartNoteUpdate?.userErrors?.length === 0) {
            this.updateCart(res.cartNoteUpdate.cart);
            return true;
        }

        return false;
    }

    async addToCart(
        merchandiseId: string,
        quantity: number = 1,
        attributes: ShopifyLineItemAttribute[] = [],
        sellingPlanId: string | null = null,
    ): Promise<boolean> {
        if (!this.cart) {
            console.error('Shopify Cart object not initialized.');
            return false;
        }

        const query = `
            mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
                cartLinesAdd(cartId: $cartId, lines: $lines) {
                    cart {
                        ${this.cartFields}
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`;

        const res = await this.query(query, {
            cartId: this.cart.id,
            lines: {
                attributes,
                merchandiseId,
                quantity,
                sellingPlanId,
            },
        });

        if (res.cartLinesAdd?.cart && res.cartLinesAdd?.userErrors?.length === 0) {
            this.updateCart(res.cartLinesAdd.cart);
            return true;
        }

        return false;
    }

    async getCart(cartId) {
        const query = `
            query cart($id: ID!) {
                cart(id: $id) {
                    ${this.cartFields}
                }
            }`;

        const res = await this.query(query, {
            id: window.btoa(cartId),
        });

        return res?.cart ?? null;
    }

    async getOrCreateCart(): Promise<ShopifyCart | null> {
        const cartId: string | null = localStorage.cartId ?? null;

        if (cartId) {
            // Get existing cart
            this.cart = await this.getCart(cartId);
        }

        if (!this.cart) {
            // Create new Cart
            const query = `
                mutation {
                    cartCreate(input: {}) {
                        cart {
                            ${this.cartFields}
                        }
                    }
                }`;

            const res = await this.query(query);

            if (res.cartCreate?.cart) {
                this.cart = res.cartCreate.cart;

                if (this.cart?.id) {
                    localStorage.cartId = this.cart.id;
                }
            }
        }

        return this.cart;
    }

    async getProductSellingPlans(handle: string): Promise<SellingPlan[]> {
        const query = `
            query product($handle: String) {
                product(handle: $handle) {
                    requiresSellingPlan
                    sellingPlanGroups(first: 1) {
                        edges {
                            node {
                                name
                                options {
                                    name
                                    values
                                }
                                sellingPlans(first: 10) {
                                    edges {
                                        node {
                                            description
                                            id
                                            name
                                            options {
                                                name
                                                value
                                            }
                                            priceAdjustments {
                                                adjustmentValue {
                                                    ... on SellingPlanFixedAmountPriceAdjustment {
                                                        __typename
                                                        adjustmentAmount {
                                                            amount
                                                            currencyCode
                                                        }
                                                    }
                                                    ... on SellingPlanFixedPriceAdjustment {
                                                        __typename
                                                        price {
                                                            amount
                                                            currencyCode
                                                        }
                                                    }
                                                    ... on SellingPlanPercentagePriceAdjustment {
                                                        __typename
                                                        adjustmentPercentage
                                                    }
                                                }
                                            }
                                            recurringDeliveries
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`;

        const res = await this.query(query, { handle });
        const sellingPlanGroup = res.product?.sellingPlanGroups?.edges[0]?.node;
        const sellingPlans: SellingPlan[] = [];

        if (sellingPlanGroup) {
            sellingPlanGroup.sellingPlans.edges.forEach(({ node: sellingPlan }) => {
                const plan: SellingPlan = {
                    discount: null,
                    discountType: null,
                    id: sellingPlan.id,
                    intervalDays: 0,
                    intervalWeeks: 0,
                    name: sellingPlan.name,
                };

                // Parse interval
                if (sellingPlan.options[0]?.value !== undefined) {
                    const matches = sellingPlan.options[0]?.value.match(/(\d+)WEEK/);
                    let days = 0;
                    let weeks = 0;

                    if (matches) {
                        plan.intervalWeeks = +matches[1];
                        plan.intervalDays = plan.intervalWeeks * 7;
                    }
                }

                // Parse price adjustments (discount)
                if (sellingPlan.priceAdjustments[0] !== undefined) {
                    const priceAdjustment: ShopifyPriceAdjustment = sellingPlan.priceAdjustments[0];

                    switch (priceAdjustment.adjustmentValue.__typename) {
                        case 'SellingPlanFixedAmountPriceAdjustment':
                            const amount = priceAdjustment.adjustmentValue.adjustmentAmount?.amount;
                            plan.discount = amount ? +amount : null;
                            plan.discountType = 'fixed';
                            break;

                        case 'SellingPlanFixedPriceAdjustment':
                            break;

                        case 'SellingPlanPercentagePriceAdjustment':
                            plan.discount = priceAdjustment.adjustmentValue.adjustmentPercentage ?? null;
                            plan.discountType = 'percentage';
                            break;
                    }
                }

                sellingPlans.push(plan);
            });
        }

        return sellingPlans;
    }

    async query(query: string, variables: object = {}): Promise<any> {
        const res = await fetch(this.endpoint, {
            body: JSON.stringify({
                query: query.trim(),
                variables,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await res.json();

        if (data.errors) {
            data.errors.forEach((error: ShopifyError) => {
                console.error(error.message);
            });

            return null;
        }

        return data.data;
    }

    updateCart(cart: ShopifyCart): void {
        this.cart = cart;

        console.log(this.cart);

        document.dispatchEvent(
            new CustomEvent('shopify:cartupdate', {
                detail: {
                    cart,
                },
            }),
        );
    }

    async removeLineItem(id: string): Promise<boolean> {
        if (!this.cart) {
            console.error('Shopify Cart object not initialized.');
            return false;
        }

        const query = `
            mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                    cart {
                        ${this.cartFields}
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`;

        const res = await this.query(query, {
            cartId: this.cart.id,
            lineIds: [id],
        });

        if (res.cartLinesRemove?.cart && res.cartLinesRemove?.userErrors?.length === 0) {
            this.updateCart(res.cartLinesRemove.cart);
            return true;
        }

        return false;
    }

    async updateLineItemQuantity(id: string | null, quantity: number, attributes: ShopifyLineItemAttribute[] = []): Promise<boolean> {
        if (!this.cart) {
            console.error('Shopify Cart object not initialized.');
            return false;
        }

        const query = `
            mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
                cartLinesUpdate(cartId: $cartId, lines: $lines) {
                    cart {
                        ${this.cartFields}
                    }
                    userErrors {
                        field
                        message
                    }
                }
            }`;

        const res = await this.query(query, {
            cartId: this.cart.id,
            lines: {
                attributes,
                id,
                quantity,
            },
        });

        if (res.cartLinesUpdate?.cart && res.cartLinesUpdate?.userErrors?.length === 0) {
            this.updateCart(res.cartLinesUpdate.cart);
            return true;
        }

        return false;
    }
}
