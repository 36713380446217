export default class Textarea {
    $el: HTMLTextAreaElement;
    minHeight: number;

    constructor($el: HTMLTextAreaElement) {
        this.$el = $el;
        this.initListeners();
    }

    initListeners(): void {
        this.$el.addEventListener('input', (e) => {
            this.$el.value = this.$el.value.replace(/\n\s*\n\s*\n/g, '\n\n');

            this.$el.style.height = '0px';
            this.$el.style.height = this.$el.scrollHeight + 'px';
        });
    }
}
