export default class Ticker {
    constructor($el, options = {}) {
        this.$el = $el;
        this.options = {
            classNameBlock: 'c-ticker__block',
            classNameContainer: 'c-ticker__container',
            classNameIsInteractive: 'is-interactive',
            direction: 'left',
            speed: 0.5,
            ...options,
        };

        // Set options from data attributes
        for (let prop in this.$el.dataset) {
            if (this.options.hasOwnProperty(prop)) {
                let value = this.$el.dataset[prop];
                value = isNaN(value) ? value : +value;
                value = value === 'true' ? true : value === 'false' ? false : value;
                this.options[prop] = value;
            }
        }

        this.init();
        this.initListeners();
    }

    animate() {
        if (this.isInteractive) {
            if (this.isPointerOver) {
                // Slow down
                if (this.speed !== 0) {
                    if (this.speed > 0.01) {
                        this.speed *= 0.975;
                    } else {
                        this.speed = 0;
                    }
                } else {
                    this.animation = window.requestAnimationFrame(this.animate.bind(this));
                    return;
                }
            } else {
                // Speed up
                if (this.speed !== this.options.speed) {
                    const diff = this.options.speed - this.speed;

                    if (diff > 0.01) {
                        this.speed += diff * 0.025;
                    } else {
                        this.speed = this.options.speed;
                    }
                }
            }
        }

        if (this.speed !== 0) {
            this.translate = this.translate + this.speed * 0.05 * this.multiplier;

            if (Math.abs(this.translate) >= this.translateMax) {
                this.translate = this.translate - this.translateMax * this.multiplier;
            }

            this.$container.style.setProperty('--ticker-translate-x', this.translate + '%');
        }

        this.animation = window.requestAnimationFrame(this.animate.bind(this));
    }

    init() {
        this.isInteractive = false;
        this.isPointerOver = false;
        this.multiplier = this.options.direction === 'left' ? -1 : 1;
        this.speed = this.options.speed;
        this.translate = 0;
        this.translateMax = 0;

        if (this.$el.querySelectorAll('a').length > 0) {
            this.$el.classList.add(this.options.classNameIsInteractive);
            this.isInteractive = true;
        }

        // Wrap ticker elements in a block
        this.$block = document.createElement('div');
        this.$block.classList.add(this.options.classNameBlock);
        this.$block.innerHTML = this.$el.innerHTML;

        // Create a container for these blocks
        this.$container = document.createElement('div');
        this.$container.classList.add(this.options.classNameContainer);
        this.$container.append(this.$block);

        // Replace root element content with the new container
        this.$el.innerHTML = '';
        this.$el.append(this.$container);

        // Cache dimensions
        this.width = this.$el.offsetWidth;
        this.blockHeight = this.$block.offsetHeight;
        this.blockWidth = this.$block.offsetWidth;

        this.update();
    }

    initListeners() {
        if (this.isInteractive) {
            this.$el.addEventListener(
                'mouseover',
                () => {
                    this.isPointerOver = true;
                },
                false,
            );

            this.$el.addEventListener(
                'mouseout',
                () => {
                    this.isPointerOver = false;
                },
                false,
            );
        }

        // Watch for scrolling
        const intersectionObserver = new IntersectionObserver((entries) => {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    // Start animation when scrolled into viewport
                    this.animation = window.requestAnimationFrame(this.animate.bind(this));
                } else {
                    // Stop animation when scrolled out of viewport
                    window.cancelAnimationFrame(this.animation);
                }
            }
        });

        intersectionObserver.observe(this.$el);

        // Watch for resizing
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === this.$el) {
                    this.width = entry.target.offsetWidth;
                }

                if (entry.target === this.$block) {
                    this.blockHeight = entry.target.offsetHeight;
                    this.blockWidth = entry.target.offsetWidth;
                }
            }

            this.update();
        });

        resizeObserver.observe(this.$el);
        resizeObserver.observe(this.$block);
    }

    update() {
        // Get number of block repetitions needed for a loop
        const repeat = Math.ceil(this.width / this.blockWidth);

        // Set container height
        this.$el.style.height = `${this.blockHeight}px`;

        // Set max translate value
        this.translateMax = 100 / (repeat + 1);

        // Remove all children but the first (this.$block)
        while (this.$container.childNodes.length > 1) {
            this.$container.removeChild(this.$container.lastChild);
        }

        // Add clones
        for (let i = 0; i < repeat; i++) {
            this.$container.append(this.$block.cloneNode(true));
        }
    }
}
