import lang from '../data/lang.json';
import Shopify, { SellingPlan, ShopifyLineItemAttribute } from '../lib/Shopify';
import Utils from '../lib/Utils';

export interface Discount {
    discount: number | null;
    discountType: 'fixed' | 'percentage' | null;
}

export interface Option {
    id: number;
    name: string;
    position: number;
    product_id: number;
    values: [string];
}

export interface ProductAddToCartEvent extends CustomEvent {
    detail: {
        attributes?: ShopifyLineItemAttribute[];
        merchandiseId: string;
        quantity: number;
        sellingPlanId: string | null;
    };
}

export interface Variant {
    admin_graphql_api_id: string;
    barcode: string;
    created_at: string;
    compare_at_price: string | null;
    fulfillment_service: string;
    grams: number;
    id: number;
    image_id: number | null;
    inventory_item_id: number;
    inventory_management: string;
    inventory_policy: string;
    inventory_quantity: number;
    old_inventory_quantity: number;
    option1: string | null;
    option2: string | null;
    option3: string | null;
    position: number;
    price: string;
    product_id: number;
    sku: string;
    requires_shipping: boolean;
    taxable: boolean;
    title: string;
    updated_at: string;
    weight: number;
    weight_unit: string;
}

export default class Product {
    $$options: NodeListOf<HTMLSelectElement | HTMLInputElement>;
    $billingPrice: HTMLElement;
    $billingDiscountPrice: HTMLElement;
    $billingDiscountValue: HTMLElement;
    $billingInterval: HTMLElement;
    $el: HTMLElement;
    $inputId: HTMLInputElement;
    $inputQuantity: HTMLInputElement;
    $inputSellingPlanId: HTMLSelectElement;
    $jsonVariants: HTMLScriptElement;
    lang: { [key: string]: string };
    productVariants: Variant[];
    selectedSellingPlan: SellingPlan | null;
    selectedVariant: Variant | null;
    shopify: Shopify;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$$options = this.$el.querySelectorAll('.js-productOption');
        this.$billingPrice = this.$el.querySelector('.js-productBillingPrice')!;
        this.$billingDiscountPrice = this.$el.querySelector('.js-productBillingDiscountPrice')!;
        this.$billingDiscountValue = this.$el.querySelector('.js-productBillingDiscountValue')!;
        this.$billingInterval = this.$el.querySelector('.js-productBillingInterval')!;
        this.$inputQuantity = this.$el.querySelector('input[name=quantity]')!;
        this.$inputId = this.$el.querySelector('input[name=id]')!;
        this.$inputSellingPlanId = this.$el.querySelector('select[name=selling_plan_id]')!;
        this.$jsonVariants = this.$el.querySelector('script[data-product-json-variants]')!;

        this.hasSellingPlans = false;
        this.lang = lang[document.documentElement.lang]?.product ?? {};
        this.productVariants = [];
        this.selectedVariant = null;
        this.shopify = new Shopify();

        if (this.$jsonVariants) {
            const productVariants = JSON.parse(this.$jsonVariants.innerText.trim());

            if (typeof productVariants === 'object') {
                this.productVariants = productVariants;
            }
        }

        this.initListeners();
        this.initSellingPlans();
        this.updateVariant();
    }

    initListeners() {
        this.$$options.forEach(($option) => {
            $option.addEventListener('change', this.onOptionChange.bind(this), false);
        });

        this.$el.addEventListener('submit', this.onSubmit.bind(this));
        this.$inputSellingPlanId.addEventListener('change', this.onOptionChange.bind(this), false);
    }

    async initSellingPlans(): Promise<void> {
        const productHandle: string | null = this.$el.dataset.productHandle ?? null;

        if (productHandle) {
            this.$inputSellingPlanId.innerHTML = '';
            const sellingPlans = await this.shopify.getProductSellingPlans(productHandle);

            // Prepend blank option
            const $option = document.createElement('option');
            $option.textContent = this.$inputSellingPlanId.dataset.labelOneTimeBuy ?? '';
            $option.value = '';
            this.$inputSellingPlanId.appendChild($option);

            // Populate dropdown menu
            sellingPlans.forEach((sellingPlan) => {
                const $option = document.createElement('option');
                $option.dataset.discount = String(sellingPlan.discount ?? '');
                $option.dataset.discountType = String(sellingPlan.discountType ?? '');
                $option.dataset.intervalDays = String(sellingPlan.intervalDays);
                $option.dataset.intervalWeeks = String(sellingPlan.intervalWeeks);
                $option.value = sellingPlan.id;

                if (sellingPlan.intervalWeeks === 1) {
                    $option.textContent = `${this.lang.every} ${this.lang.weeks_one}`;
                } else {
                    $option.textContent = `${this.lang.every} ${sellingPlan.intervalWeeks} ${this.lang.weeks_many}`;
                }

                this.$inputSellingPlanId.appendChild($option);
            });

            // Update UI
            this.$inputSellingPlanId.dispatchEvent(new Event('change'));
            this.hasSellingPlans = sellingPlans.length > 0;
        }
    }

    onOptionChange() {
        this.updateVariant();
    }

    onSubmit(e: SubmitEvent) {
        e.preventDefault();

        let merchandiseId: string = this.$inputId.value;
        let quantity: number = +this.$inputQuantity.value;
        let sellingPlanId: string | null = this.$inputSellingPlanId.value;

        if (sellingPlanId.trim() === '') {
            sellingPlanId = null;
        }

        document.dispatchEvent(
            new CustomEvent('product:addtocart', {
                detail: {
                    merchandiseId,
                    quantity,
                    sellingPlanId,
                },
            }),
        );
    }

    updatePrice() {
        const $sellingPlanOption = this.$inputSellingPlanId.options[this.$inputSellingPlanId.selectedIndex];
        let discount: number | null = null;
        let discountType: string | null = null;

        if ($sellingPlanOption instanceof HTMLOptionElement) {
            if ($sellingPlanOption.value !== '') {
                discount = $sellingPlanOption.dataset.discount ? +$sellingPlanOption.dataset.discount : null;
                discountType = $sellingPlanOption.dataset.discountType ?? null;

                if ($sellingPlanOption.textContent) {
                    this.$billingInterval.textContent = $sellingPlanOption.textContent.toLocaleLowerCase();
                }
            } else {
                this.$billingInterval.textContent = '';
            }
        }

        if (this.selectedVariant) {
            this.$billingPrice.textContent = Utils.formatCurrency(+this.selectedVariant.price);

            if (discount && discountType) {
                let priceDiscounted: number;

                switch (discountType) {
                    case 'fixed':
                        priceDiscounted = +this.selectedVariant.price - discount;
                        this.$billingDiscountPrice.textContent = Utils.formatCurrency(priceDiscounted);
                        this.$billingDiscountValue.textContent = `(-${Utils.formatCurrency(discount)})`;
                        break;

                    case 'percentage':
                        priceDiscounted = +this.selectedVariant.price * ((100 - discount) / 100);
                        this.$billingDiscountPrice.textContent = Utils.formatCurrency(priceDiscounted);
                        this.$billingDiscountValue.textContent = `(-${discount}%)`;
                        break;
                }

                this.hasDiscount = true;
            } else {
                this.$billingDiscountPrice.textContent = '';
                this.$billingDiscountValue.textContent = '';
                this.hasDiscount = false;
            }
        } else {
            if (this.productVariants) {
                const max = this.productVariants.reduce((prev, cur) => (+prev.price > +cur.price ? prev : cur));
                const min = this.productVariants.reduce((prev, cur) => (+prev.price < +cur.price ? prev : cur));

                if (min.price !== max.price) {
                    this.$billingPrice.textContent = Utils.formatCurrency(+min.price) + ' – ' + Utils.formatCurrency(+max.price);
                }
            }
        }
    }

    updateVariant() {
        const selectedOptions = {};

        if (this.$$options.length) {
            this.$$options.forEach(($option) => {
                if ($option instanceof HTMLSelectElement) {
                    selectedOptions[$option.name] = $option.value;
                }

                if ($option instanceof HTMLInputElement) {
                    if (['checkbox', 'radio'].includes($option.type)) {
                        if ($option.checked) {
                            selectedOptions[$option.name] = $option.value;
                        }
                    }
                }
            });

            console.log(selectedOptions);

            const selectedVariant =
                this.productVariants.filter((variant) => {
                    return Object.keys(selectedOptions).every((key) => {
                        return variant[key] === selectedOptions[key];
                    });
                })[0] ?? null;

            if (selectedVariant) {
                this.selectedVariant = selectedVariant;
                this.$inputId.value = `gid://shopify/ProductVariant/${selectedVariant.id}`;
            }
        }

        this.updatePrice();
    }

    /**
     * Getters & setters
     */

    get hasDiscount(): boolean {
        return this.$el.classList.contains('has-discount');
    }

    set hasDiscount(hasDiscount: boolean) {
        this.$el.classList.toggle('has-discount', hasDiscount);
    }

    get hasSellingPlans(): boolean {
        return this.$el.classList.contains('has-sellingPlan');
    }

    set hasSellingPlans(hasSellingPlan: boolean) {
        this.$el.classList.toggle('has-sellingPlans', hasSellingPlan);
    }
}
