export default class InputSelect {
    $el: HTMLSelectElement;
    $label: HTMLLabelElement;
    $wrapper: HTMLDivElement;

    constructor($el:HTMLSelectElement) {
        this.$el = $el;
        this.$el.classList.add('c-inputSelect__select');

        // Add wrapper
        this.$wrapper = document.createElement('div');
        this.$wrapper.classList.add('c-inputSelect');
        this.$el.parentNode!.insertBefore(this.$wrapper, this.$el);
        this.$wrapper.appendChild(this.$el);

        // Add label
        this.$label = document.createElement('label');
        this.$label.classList.add('c-inputSelect__label');
        this.$label.setAttribute('for', this.$el.id ?? '');
        this.$label.textContent = this.label;
        this.$el.parentNode!.insertBefore(this.$label, this.$el.nextSibling);

        this.initListeners();
    }

    initListeners(): void {
        this.$el.addEventListener('change', this.onChange.bind(this));
    }

    onChange(): void {
        this.$label.textContent = this.label;
    }

    /**
     * Getters & setters
     */

    get label(): string {
        if (!this.$el.options.length) {
            return '';
        }

        const index = (this.$el.selectedIndex > -1) ? this.$el.selectedIndex : 0;
        return this.$el.options[index].innerText.trim();
    }
}