export default class Title {
    $el: HTMLElement;

    constructor($el: HTMLElement) {
        this.$el = $el;

        const words = this.$el.innerText.split(/\s+/);
        this.$el.innerHTML = '';

        words.forEach((word) => {
            const $inner = document.createElement('span');
            $inner.className = 'c-title__wordInner';
            $inner.textContent = word;

            const $word = document.createElement('span');
            $word.className = 'c-title__word';
            $word.appendChild($inner);

            this.$el.append($word);
        });
    }
}
