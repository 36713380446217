export interface QuantityEvent extends CustomEvent {
    detail: {
        quantity: number;
    };
};

export default class Quantity {
    $el: HTMLElement;
    $buttonDec: HTMLButtonElement;
    $buttonInc: HTMLButtonElement;
    $input: HTMLInputElement;
    max: number | null;
    min: number | null;

    constructor($el:HTMLElement) {
        this.$el = $el;

        this.$buttonDec = this.$el.querySelector('.js-quantityButtonDec')!;
        this.$buttonInc = this.$el.querySelector('.js-quantityButtonInc')!;
        this.$input = this.$el.querySelector('input')!;
        this.max = this.$el.dataset.quantityMax ? +this.$el.dataset.quantityMax : null;
        this.min = this.$el.dataset.quantityMin ? +this.$el.dataset.quantityMin : null;

        this.initListeners();
        this.update();
    }

    initListeners(): void {
        this.$buttonDec!.addEventListener('click', this.onButtonDecClick.bind(this));
        this.$buttonInc!.addEventListener('click', this.onButtonIncClick.bind(this));
    }

    onButtonDecClick(e: PointerEvent): void {
        const quantity = this.quantity;
        
        if (this.min === null || quantity > this.min) {
            this.quantity = quantity - 1;

            this.$el.dispatchEvent(new CustomEvent('quantity:change', {
                bubbles: true,
                detail: {
                    quantity: this.quantity,
                }
            }));

            this.$el.dispatchEvent(new CustomEvent('quantity:decrement', {
                bubbles: true,
                detail: {
                    quantity: this.quantity,
                }
            }));

            this.$input.dispatchEvent(new Event('change'));
        }
        
        this.update();
    }

    onButtonIncClick(e: PointerEvent): void {
        const quantity = this.quantity;

        if (this.max === null || quantity < this.max) {
            this.quantity = quantity + 1;

            this.$el.dispatchEvent(new CustomEvent('quantity:change', {
                detail: {
                    quantity: this.quantity,
                }
            }));

            this.$el.dispatchEvent(new CustomEvent('quantity:increment', {
                bubbles: true,
                detail: {
                    quantity: this.quantity,
                }
            }));

            this.$input.dispatchEvent(new Event('change'));
        }
        
        this.update();
    }

    update(): void {
        this.$el.classList.toggle('is-max', this.max !== null && this.quantity === this.max);
        this.$el.classList.toggle('is-min', this.min !== null && this.quantity === this.min);
    }

    /**
     * Getters & setters
     */

    get quantity(): number {
        return +this.$input!.value;
    }

    set quantity(quantity: number) {
        this.$input.value = String(quantity);
    }
}