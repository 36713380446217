export default class Gallery {
    $$images: NodeListOf<HTMLElement>;
    $$nav: NodeListOf<HTMLButtonElement>;
    $bullets: HTMLUListElement;
    $el: HTMLElement;
    $images: HTMLElement;
    currentIndex: number;
    numImages: number;
    width: number;

    constructor($el:HTMLElement) {
        this.$el = $el;
        this.$$images = this.$el.querySelectorAll('.js-galleryImage');
        this.$$nav = this.$el.querySelectorAll('.js-galleryNav');
        this.$bullets = this.$el.querySelector('.js-galleryBullets')!;
        this.$images = this.$el.querySelector('.js-galleryImages')!;

        this.currentIndex = 0;
        this.numImages = this.$$images.length;
        this.width = this.$el.offsetWidth;

        this.initBullets();
        this.initListeners();
    }

    initBullets(): void {
        let $bullet: HTMLLIElement;

        for (let i = 0; i < this.numImages; i++) {
            $bullet = document.createElement('li');
            $bullet.dataset.index = String(i);
            this.$bullets.appendChild($bullet);
        }

        this.setIndex(0);
    }

    initListeners(): void {
        this.$$nav.forEach(($nav) => {
            $nav.addEventListener('click', this.onNavClick.bind(this), false);
        });

        this.$bullets.addEventListener('click', this.onBulletClick.bind(this), false);
        this.$images.addEventListener('scroll', this.onImagesScroll.bind(this), false);
    }

    onBulletClick(e: PointerEvent): void {
        if (e.target instanceof HTMLLIElement) {
            const $bullet = e.target;

            if ($bullet.dataset.index) {
                const index = +$bullet.dataset.index;
                this.scrollToIndex(index);
            }
        }
    }

    onImagesScroll(): void {
        const index = Math.round(this.$images.scrollLeft / this.width);

        if (index !== this.currentIndex) {
            this.setIndex(index);
        }
    }

    onNavClick(e: PointerEvent): void {
        const $nav = e.target;

        if ($nav instanceof HTMLButtonElement) {
            const direction = $nav.dataset.direction ? +$nav.dataset.direction : 0;
            this.scrollToIndex(this.currentIndex + direction);
        }
    }

    scrollToIndex(index: number): void {
        this.$images.scrollTo({
            behavior: 'smooth',
            left: this.width * index,
            top: 0, 
        });
    }

    setIndex(index: number): void {
        this.$el.classList.toggle('is-first', index === 0);
        this.$el.classList.toggle('is-last', index === (this.numImages - 1));
        this.currentIndex = index;

        Array.from(this.$bullets.children).forEach(($bullet) => {
            $bullet.classList.remove('is-active');
        });

        const $bullet = this.$bullets.children[index];

        if ($bullet instanceof HTMLLIElement) {
            $bullet.classList.add('is-active');
        }
    }
}