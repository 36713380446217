export default class LanguageSwitch {
    $el: HTMLElement;
    $select: HTMLSelectElement;

    constructor($el:HTMLElement) {
        this.$el = $el;
        this.$select = this.$el.querySelector('.js-languageSwitchSelect')!;

        this.initListeners();
    }

    initListeners(): void {
        this.$select.addEventListener('change', this.onSelectChange.bind(this), false);
    }

    onSelectChange(): void {
        window.location.href = this.$select.value;
    }
}