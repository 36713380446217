import { ShopifyCartLineItem, ShopifyLineItemAttribute, ShopifyPriceAdjustment } from '../lib/Shopify';
import Utils from '../lib/Utils';
import { QuantityEvent } from './Quantity';

export interface CartLineItemRemoveEvent extends CustomEvent {
    detail: {
        id: string | null;
    };
};

export interface CartLineItemUpdateQuantityEvent extends CustomEvent {
    detail: {
        attributes: ShopifyLineItemAttribute[];
        id: string | null;
        quantity: number;
    };
};

export default class CartLineItem {
    $attributes: HTMLElement;
    $el: HTMLLIElement;
    $image: HTMLImageElement;
    $inputQuantity: HTMLInputElement;
    $price: HTMLElement;
    $priceDiscounted: HTMLElement;
    $quantity: HTMLElement;
    $remove: HTMLButtonElement;
    $subtotal: HTMLElement;
    $title: HTMLElement;
    attributes: ShopifyLineItemAttribute[];
    id: string | null;
    price: number;
    priceDiscounted: number | null;
    sellingPlanName: string | null;
    quantity: number;

    constructor($el: HTMLLIElement) {
        this.$el = $el;
        this.$attributes = this.$el.querySelector('.js-cartLineItemAttributes')!;
        this.$image = this.$el.querySelector('.js-cartLineItemImage')!;
        this.$inputQuantity = this.$el.querySelector('input[name=quantity]')!;
        this.$price = this.$el.querySelector('.js-cartLineItemPrice')!;
        this.$priceDiscounted = this.$el.querySelector('.js-cartLineItemPriceDiscounted')!;
        this.$quantity = this.$el.querySelector('.js-cartLineItemQuantity')!;
        this.$remove = this.$el.querySelector('.js-cartLineItemRemove')!;
        this.$subtotal = this.$el.querySelector('.js-cartLineItemSubtotal')!;
        this.$title = this.$el.querySelector('.js-cartLineItemTitle')!;

        this.attributes = [];
        this.hasDiscount = false;
        this.hasSellingPlan = false;
        this.id = null;
        this.price = 0;
        this.priceDiscounted = null;
        this.quantity = 1;

        this.initListeners();
    }

    init(lineItem: ShopifyCartLineItem): void {
        this.attributes = lineItem.attributes;
        this.id = lineItem.id;
        this.price = +lineItem.merchandise.price.amount;
        this.quantity = lineItem.quantity;

        const sellingPlan = lineItem.sellingPlanAllocation?.sellingPlan;

        if (sellingPlan) {
            this.hasSellingPlan = true;

            if (sellingPlan.priceAdjustments.length) {
                const priceAdjustment: ShopifyPriceAdjustment = sellingPlan.priceAdjustments[0];
                let discount;

                switch (priceAdjustment.adjustmentValue.__typename) {
                    case 'SellingPlanFixedAmountPriceAdjustment':
                        if (priceAdjustment.adjustmentValue.adjustmentAmount?.amount) {
                            discount = +priceAdjustment.adjustmentValue.adjustmentAmount.amount;
                            this.hasDiscount = true;
                            this.priceDiscounted = this.price - discount;
                        }
                        break;

                    case 'SellingPlanFixedPriceAdjustment':
                        // Not sure when this applies?
                        break;

                    case 'SellingPlanPercentagePriceAdjustment':
                        if (priceAdjustment.adjustmentValue.adjustmentPercentage) {
                            discount = priceAdjustment.adjustmentValue.adjustmentPercentage;
                            this.hasDiscount = true;
                            this.priceDiscounted = this.price * ((100 - discount) / 100);
                        }
                        break;
                }
            }
        }

        this.$el.dataset.quantity = String(this.quantity);
        this.$el.dataset.price = String(this.priceDiscounted ?? this.price);
        this.$image.src = lineItem.merchandise.product.featuredImage.transformedSrc,
        this.$title.textContent = lineItem.merchandise.product.title;
        this.$price.textContent = Utils.formatCurrency(this.price);
        this.$subtotal.textContent = Utils.formatCurrency((this.priceDiscounted ?? this.price) * this.quantity);

        if (this.priceDiscounted) {
            this.$priceDiscounted.textContent = Utils.formatCurrency(this.priceDiscounted);
        }

        // Init quantity
        this.$inputQuantity.value = String(this.quantity);
        this.$inputQuantity.dispatchEvent(new Event('blur'));

        // Init attributes
        const attributesHTML = this.attributes.filter((attribute) => {
                return (attribute.key.charAt(0) !== '_');
            }).map((attribute) => {
                return attribute.value;
            });

        if (sellingPlan) {
            attributesHTML.push(sellingPlan.name);
        }

        this.$attributes.innerHTML = attributesHTML.join('<br>');
    }

    initListeners(): void {
        this.$quantity.addEventListener('quantity:change', this.onQuantityChange.bind(this), false);
        this.$remove.addEventListener('click', this.onRemoveClick.bind(this), false);

        this.$subtotal.addEventListener('animationend', () => {
            this.$subtotal.classList.remove('is-decrement', 'is-increment');
        }, false);
    }

    onQuantityChange(e: QuantityEvent) {
        if (e.detail.quantity > 0) {
            this.updateQuantity(e.detail.quantity);
        } else {
            this.remove();
        }
    }

    onRemoveClick() {
        this.remove();
    }

    remove() {
        this.$el.addEventListener('transitionend', () => {
            this.$el.dispatchEvent(
                new CustomEvent('cartlineitem:remove', {
                    bubbles: true,
                    detail: {
                        id: this.id,
                    },
                }),
            );

            this.$el.parentNode?.removeChild(this.$el);
        }, false);

        this.$el.style.height = `${this.$el.offsetHeight}px`;

        window.requestAnimationFrame(() => {
            this.$el.classList.add('is-removing');
        });
    }

    updateQuantity(quantity: number): void {
        this.$subtotal.classList.add((quantity > this.quantity) ? 'is-increment' : 'is-decrement');
        this.quantity = quantity;
        this.$subtotal.textContent = Utils.formatCurrency((this.priceDiscounted ?? this.price) * this.quantity);

        this.$el.dataset.quantity = String(quantity);
        this.$el.dispatchEvent(
            new CustomEvent('cartlineitem:updatequantity', {
                bubbles: true,
                detail: {
                    attributes: this.attributes,
                    id: this.id,
                    quantity: this.quantity,
                },
            }),
        );
    }

    /**
     * Getters & setters
     */

    get hasDiscount(): boolean {
        return this.$el.classList.contains('has-discount');
    }

    set hasDiscount(hasDiscount: boolean) {
        this.$el.classList.toggle('has-discount', hasDiscount);
    }

    get hasSellingPlan(): boolean {
        return this.$el.classList.contains('has-sellingPlan');
    }

    set hasSellingPlan(hasSellingPlan: boolean) {
        this.$el.classList.toggle('has-sellingPlan', hasSellingPlan);
    }
}